import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import ButtonIcon from "../Button/ButtonIcon";

const About = ({ headingLevel, className }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section className={` ${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <header className="mb-12 text-center md:mb-16">
          <HeadingTag>Helpful Resources</HeadingTag>
        </header>
        <div className="grid items-center gap-y-10 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
          <Link
            to="/home-loan-process/"
            className="group block overflow-hidden rounded-3xl bg-white font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
          >
            <StaticImage
              src="../../images/0.0 Repeating Modules/Resources/5-Step Loan Process.jpg"
              loading="lazy"
              width={746}
              className="w-full"
            />
            <div className="bg-white p-6">
              <h3 className="heading-six">5-Step Loan Process</h3>
              <p className="mb-4">
                Get details about our easy 5-step loan process—from getting
                prequalified to wrapping up the closing.
              </p>
              <ButtonIcon
                href="/home-loan-process/"
                text="Learn More"
                altStyle={2}
              />
            </div>
          </Link>
          <Link
            to="/mortgage-basics/"
            className="group block overflow-hidden rounded-3xl bg-white font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
          >
            <StaticImage
              src="../../images/0.0 Repeating Modules/Resources/Mortgage Basics.jpg"
              loading="lazy"
              width={746}
              className="w-full"
            />
            <div className="bg-white p-6">
              <h3 className="heading-six">Mortgage Basics</h3>
              <p className="mb-4">
                Learn about the mortgage terms you should know when buying a
                house or refinancing a loan.
              </p>
              <ButtonIcon
                href="/mortgage-basics/"
                text="Learn More"
                altStyle={2}
              />
            </div>
          </Link>
          <Link
            to="/mortgage-calculators/"
            className="group block overflow-hidden rounded-3xl bg-white font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
          >
            <StaticImage
              src="../../images/0.0 Repeating Modules/Resources/Mortgage Calcs.jpg"
              loading="lazy"
              width={746}
              className="w-full"
            />
            <div className="bg-white p-6">
              <h3 className="heading-six">Mortgage Calculators</h3>
              <p className="mb-4">
                Get valuable insight into how much home you can afford, the tax
                benefits of buying, and more!
              </p>
              <ButtonIcon
                href="/mortgage-calculators/"
                text="Learn More"
                altStyle={2}
              />
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default About;
