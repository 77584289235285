import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import { mapEdgesToNodes } from "../../lib/helpers";

const LoanPrograms = ({ heading, headingLevel, location, className }) => {
  const HeadingTag = headingLevel || "h2";

  const data = useStaticQuery(graphql`
    {
      loanPrograms: allSanityCityLoanPrograms(
        filter: { slug: { current: { ne: null } } }
      ) {
        edges {
          node {
            id
            city {
              title
            }
            slug {
              current
            }
            loanProgram
          }
        }
      }
    }
  `);

  const fhaNodes = (data || {}).loanPrograms
    ? mapEdgesToNodes(data.loanPrograms)
        .filter((items) => items.city.title === location)
        .filter((items) => items.loanProgram === "FHA")
    : [];

  const vaNodes = (data || {}).loanPrograms
    ? mapEdgesToNodes(data.loanPrograms)
        .filter((items) => items.city.title === location)
        .filter((items) => items.loanProgram === "VA")
    : [];

  const conventionalNodes = (data || {}).loanPrograms
    ? mapEdgesToNodes(data.loanPrograms)
        .filter((items) => items.city.title === location)
        .filter((items) => items.loanProgram === "Conventional")
    : [];

  const usdaNodes = (data || {}).loanPrograms
    ? mapEdgesToNodes(data.loanPrograms)
        .filter((items) => items.city.title === location)
        .filter((items) => items.loanProgram === "USDA")
    : [];

  const jumboNodes = (data || {}).loanPrograms
    ? mapEdgesToNodes(data.loanPrograms)
        .filter((items) => items.city.title === location)
        .filter((items) => items.loanProgram === "Jumbo")
    : [];

  return (
    <section
      className={`bg-primary-50 py-20 md:py-32 ${
        className || "mb-20 md:mb-32"
      }`}
    >
      <div className="container">
        <header className="mb-10 text-center md:mb-16">
          <HeadingTag>{heading || "Which Loan Is Right for You?"}</HeadingTag>
        </header>
        <div className="grid gap-y-12 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
          <Link
            to={
              fhaNodes.length > 0
                ? fhaNodes.map((item) => `/${item.slug.current}/`)
                : "/fha-home-loans/"
            }
            className={`block overflow-hidden rounded-3xl bg-white px-4 py-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10`}
          >
            <div className="mb-6">
              <StaticImage
                src="../../images/0.0 Repeating Modules/Loan Program Cards/FHA.jpg"
                loading="lazy"
                width={153}
                className="z-0 w-[96px] rounded-full md:w-[153px]"
              />
            </div>
            <h3 className="heading-four mb-3">FHA</h3>
            <p className="mb-0">
              FHA loans are perfect for first-time home buyers—they offer low
              down payments and have reasonable income and credit expectations.
            </p>
          </Link>

          <Link
            to={
              vaNodes.length > 0
                ? vaNodes.map((item) => `/${item.slug.current}/`)
                : "/va-home-loans/"
            }
            className={`block overflow-hidden rounded-3xl bg-white px-4 py-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10`}
          >
            <div className="mb-6">
              <StaticImage
                src="../../images/0.0 Repeating Modules/Loan Program Cards/VA.jpg"
                loading="lazy"
                width={153}
                className="z-0 w-[96px] rounded-full md:w-[153px]"
              />
            </div>
            <h3 className="heading-four mb-3">VA</h3>
            <p className="mb-0">
              VA loans offer exceptional benefits for military veterans and
              active service members, such as low interest rates and no money
              down.
            </p>
          </Link>

          <Link
            to={
              conventionalNodes.length > 0
                ? conventionalNodes.map((item) => `/${item.slug.current}/`)
                : "/conventional-home-loans/"
            }
            className={`block overflow-hidden rounded-3xl bg-white px-4 py-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10`}
          >
            <div className="mb-6">
              <StaticImage
                src="../../images/0.0 Repeating Modules/Loan Program Cards/Conventional.jpg"
                loading="lazy"
                width={153}
                className="z-0 w-[96px] rounded-full md:w-[153px]"
              />
            </div>
            <h3 className="heading-four mb-3">Conventional</h3>
            <p className="mb-0">
              A conventional loan is a great option for those with a solid
              credit score and minimal debt.
            </p>
          </Link>

          <Link
            to={
              usdaNodes.length > 0
                ? usdaNodes.map((item) => `/${item.slug.current}/`)
                : "/usda-home-loans/"
            }
            className={`block overflow-hidden rounded-3xl bg-white px-4 py-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10`}
          >
            <div className="mb-6">
              <StaticImage
                src="../../images/0.0 Repeating Modules/Loan Program Cards/USDA.jpg"
                loading="lazy"
                width={153}
                className="z-0 w-[96px] rounded-full md:w-[153px]"
              />
            </div>
            <h3 className="heading-four mb-3">USDA</h3>
            <p className="mb-0">
              If your dream home is in a rural area, a USDA loan offers no down
              payment and a minimal investment.
            </p>
          </Link>

          <Link
            to={
              jumboNodes.length > 0
                ? jumboNodes.map((item) => `/${item.slug.current}/`)
                : "/jumbo-home-loans/"
            }
            className={`block overflow-hidden rounded-3xl bg-white px-4 py-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10`}
          >
            <div className="mb-6">
              <StaticImage
                src="../../images/0.0 Repeating Modules/Loan Program Cards/Jumbo.jpg"
                loading="lazy"
                width={153}
                className="z-0 w-[96px] rounded-full md:w-[153px]"
              />
            </div>
            <h3 className="heading-four mb-3">Jumbo</h3>
            <p className="mb-0">
              If you’re interested in higher-priced homes, you can apply for a
              jumbo loan to finance one that exceeds conforming loan limits.
            </p>
          </Link>

          <button
            data-modal-open="modal-contact"
            className={`block overflow-hidden rounded-3xl bg-white px-4 py-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10`}
          >
            <div className="mb-6">
              <StaticImage
                src="../../images/0.0 Repeating Modules/Loan Program Cards/Need Help.jpg"
                loading="lazy"
                width={153}
                className="z-0 w-[96px] rounded-full md:w-[153px]"
              />
            </div>
            <h3 className="heading-four mb-3">Need Help?</h3>
            <p className="mb-0">
              Our mortgage advisors are happy to review loan programs with you
              to see which one is the best fit.
            </p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default LoanPrograms;
